<template>
  <div class="product-feeds__page">
    <v-row no-gutters>
      <v-col cols="12" class="page-header d-flex align-center">
        <h1 class="page-title">Product Feeds</h1>
        <v-btn @click="$router.push({ name: 'website.product-feeds.create' })" class="ml-auto" color="primary"
          >Add Product Feed</v-btn
        >
      </v-col>
      <v-col cols="12">
        <p>
          A product feed, otherwise known as a data feed contains all of your product's information. You can upload it
          to shopping channels like Google Shopping and Facebook to communicate all the information your product
          listings will contain. If having any changes, product feeds will be updated automatically at 11:00 AM every
          day.
        </p>
      </v-col>
      <v-col cols="12" class="elevation-form px-5 py-5">
        <Resource
          :document="document"
          :searchable="searchableField"
          :columns="columns"
          :update-router-callback="getUpdateRouterData"
          :routeName="updateRouteName"
        />
        <v-col cols="12" class="d-flex justify-space-between">
          <limit-page :callback="setLimit" />
          <pagination :length="lengthPage" v-if="!$parent.isLoading && document" :page.sync="page"></pagination>
        </v-col>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import Resource from '@/components/Resources';
import filter from '@/const/filters/product-filter';
import resourceListMixin from '@/mixins/resource-list';
import { productApi } from '@/apis/product';
import utilities from '@/helpers/utilities';
import { collectionApi } from '@/apis/collection';
import { imageFake } from '@/const/image';

export default {
  components: { Resource },
  mixins: [resourceListMixin],
  data() {
    return {
      resourceType: 'product-feed',
      // page: 1,
      length: 1,
      action: '',
      count: 1,
      columns: {
        NAME: {
          // blankLabel: true,
          kind: 'title',
          field: 'name',
        },
        'TOTAL PRODUCT': {
          field: 'totalProduct',
          kind: 'text',
        },
        'LAST REFRESHED AT': {
          field: 'startDate',
          kind: 'datetime',
        },
        'PRODUCT FEED URL': {
          field: 'feedUrl',
          kind: 'url',
        },
        STATUS: {
          field: 'status',
          kind: 'status-feed',
        },
        // Actions: {
        //   kind: 'actions',
        //    blankLabel: true,
        // },
      },
      searchableField: 'with title',
      sortable: [],
      filter,
      updateRouteName: 'website.product-feeds.update',
      isLoadingCollection: false,
    };
  },
};
</script>
